/* eslint-disable */
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';

import Header from '../../components/Header/';
import Sidebar from '../../components/Sidebar/';

import { Home } from '../../components/Home';
//import Breadcrumb from '../../components/Breadcrumb/';

//import Footer from '../../components/Footer/';

import Dashboard from '../../views/Dashboard/';
import Broadcast from '../../views/Broadcast/';
import BroadcastNewAndEdit from '../../views/Broadcast/NewAndEdit/';
import TemplateList from '../../views/Template/List/';
import TemplateNew from '../../views/Template/New/';
import SenderList from '../../views/Sender/List/';
import PeoplePerson from '../../views/People/Person/';
import PeopleDetail from '../../views/People/Person/Detail/';
import PeopleTag from '../../views/People/Tag/';
import PeopleTagDetail from '../../views/People/Tag/Detail/';
import PeopleBlacklist from '../../views/People/Blacklist/';
import ReportCommunication from '../../views/Report/Communication/';
import Report from '../../views/Report/ReportNew';
import ReportNew from '../../views/Report/ReportNew';
import ReportCreate from '../../views/Report/Create/Createnew';
import ReportCreateDetailreportnew from '../../views/Report/Create/Detailreportnew';
import ReportCreateSummeryReportnew from '../../views/Report/Create/SummeryReportnew';
import Log from '../../views/LogV2/';
import OtpConfiguration from '../../views/Otp/Configuration/';
import OtpConfigurationAdd from '../../views/Otp/Configuration/OtpAdd';
import OtpOtpLog from '../../views/Otp/OtpLog/';
import OtpOtpLogDetial from '../../views/Otp/OtpLog/Detail/';
import IssueReport from '../../views/IssueReport/';
import Detail from '../../views/Broadcast/Communication/Detail';

import AccountList from '../../views/UsersManagement/AccountList';
import AccountsDetail from '../../views/UsersManagement/AccountsDetail';
import CreditMovement from '../../views/CreditMovement';
import { Hello } from '../../views/Pages/Hello/Hello';
import { token, capitalizeFLetter, CheckTokenExp } from '../../config';
import { apiClient } from '../../api';
import { Spin, Icon } from 'antd';
import ModalServerError from '../../components/Ants/Modal/ModalServerError';

export class Full extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
            username: "",
            admin: false,
            createuser: false,
            createaccount: false,
            userGenToken: false,
            startPage:false,
            balance: 0,
            type: 0,
            get: 0,
            loading: false,
            modalVisible: false,
        };

        this.getToken = this.getToken.bind(this);
        this.validateToken = this.validateToken.bind(this);
        this.Getpermission = this.Getpermission.bind(this);
        this.getcredit = this.getcredit.bind(this);

    }

    componentDidMount() {
       
    }

    componentWillMount(){
        this.getToken("__AntiXsrfUerkey");
        if (token) {
            this.Getpermission()
            setTimeout(() => { this.getcredit() }, 3000)
        }
    }

    componentWillUnmount() {

    }

    getcredit() {
        if (!CheckTokenExp()) {
            if (!this.state.loading) {
                this.setState({ loading: true });
                let req = JSON.stringify({
                });

                // cream comment
                apiClient.post('api/credit/get_credit_byuser', req
                    , {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        }
                    })
                    .then(data => {
                        var json = data.data;
                        this.setState({ balance: json.balance, type: json.paytype, loading: false, get: 1 });
                    })
                    .catch(error => {
                        this.setState({ modalVisible: true });
                        this.setState({ balance: 0, type: 1, loading: false, get: 1 }, () => {});
                    });
            }
        } else {
            window.location.href = "logout";
        }
    }

    getToken(cname) {
        var token = document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1")
        if (token == "") {
            //window.location.reload();
            window.location.href = "logout";
        } else {
            this.validateToken(token);
        }
        return "";
    }

    validateToken(token) {
        if (!CheckTokenExp()) {
            apiClient.post('api/ValidateToken', JSON.stringify(token)
                , { headers: { 'Content-type': 'application/json; charset=UTF-8' } })
                .then(data => {
                    var json = data.data;
                    this.setState({ username: json.username, company: json.company });
                })
                .catch(error => {
                    window.location.href = "logout";
                });
        } else {
            window.location.href = "logout";
        }

    }

    Getpermission() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });
            apiClient.post('api/usersmanage/Getpermission', {}
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                .then(data => {
                    var json = data.data;
                    this.setState({
                        admin: json.admin,
                        createuser: json.createuser,
                        createaccount: json.createaccount,
                        userGenToken: json.userGenToken,
                        loading: false,
                        startPage:true
                    });
                })
                .catch(error => {
                    this.setState({
                        admin: false,
                        createuser: false,
                        createaccount: false,
                        loading: false,
                        startPage:true
                    });
                });
        } else {
            window.location.href = "logout";
        }
    }
    

    render() {
        const { username, company, admin, createuser, createaccount, userGenToken, balance, type, errormsg, loading, get, modalVisible } = this.state;
        const antIcon = <Icon type="loading" theme="outlined" spin />;

        return (
            <>
                {/* <Spin className='spin-loading-ants' spinning={loading} indicator={antIcon}></Spin> */}
                {this.state.startPage ?
                    <div className="app" onClick={this.expAdd}>
                        <Header {...this.props} username={username} company={company} admin={admin} createuser={createuser} userGenToken={userGenToken} getCreditbalance={() => { this.setState({ get: 0 }); this.getcredit() }} createaccount={createaccount} balance={balance} type={type} loading={get == 1 ? false : loading} onLoadCredit={(e) => { this.getcredit() }} />
                        <div className="app-body">
                            <Sidebar {...this.props} />
                            <main className="main">
                                <Container fluid>
                                    <Switch>
                                        {
                                            <Route path="/dashboard" name="Dashboard" component={Dashboard} />
                                        /* 
                                
                                    
                                    
                                        <Route exact path="/otp/configuration" name="OtpConfiguration" component={OtpConfiguration} />
                                        <Route path="/otp/configuration/otpadd" name="OtpConfigurationAdd" component={OtpConfigurationAdd} />
                                        <Route exact path="/otp/log" name="OtpLog" component={OtpOtpLog} />
                                        <Route path="/otp/log/detail" name="OtpLog detial" component={OtpOtpLogDetial} />
                                        <Route path="/contactus" name="feedback" component={IssueReport} />
                                        <Route exact path="/profile/" name="Profile" component={Profile} />
                                        {
                                            admin || createuser || createaccount ?
                                                <Route exact path="/accountmanagement/" name="AccountList"
                                                    render={() => (
                                                        <AccountList onGetcreditbalance={this.getcredit} />
                                                    )} />
                                                :
                                                null
                                        }*/}
                                        {/*createaccount ? <Route exact path="/accountmanagement/" name="UsertList" component={AccountList} /> : null*/}
                                        {/* {createaccount ? <Route path="/accountmanagement/userDetail" name="UserDetail" component={AccountsDetail} /> : null}
                                        {admin || createuser || createaccount ? <Route path="/accountmanagement/userList" name="UserList" component={AccountList} /> : null}
                                        {admin || createuser || createaccount ? <Route path="/creditmovement" name="CreditMovement" component={CreditMovement} /> : null}  */}
                                        <Route exact path="/communication/communication" name="Communication" component={Detail} />
                                        <Route exact path="/communication" name="Broadcast" component={Broadcast} />
                                        <Route path="/communication/new" name="BroadcastNew" component={BroadcastNewAndEdit} />
                                        <Route path="/communication/edit" name="BroadcastEdit" component={BroadcastNewAndEdit} />
                                        <Route path="/communication/template/list" name="TemplateList" component={TemplateList} />
                                        <Route path="/communication/template/new" name="TemplateNew" component={TemplateNew} />
                                        <Route path="/communication/template/edit" name="TemplateEdit" component={TemplateNew} />
                                        <Route path="/communication/sender/list" name="SenderList" component={SenderList} />

                                        <Route exact path="/people/" name="People" component={PeoplePerson} />
                                        <Route exact path="/people/person" name="PeoplePerson" component={PeoplePerson} />
                                        <Route path="/people/person/detail" name="PeopleDetail" component={PeopleDetail} />
                                        <Route exact path="/people/tag" name="PeopleTag" component={PeopleTag} />
                                        <Route path="/people/tag/detail" name="PeopleTagDetail" component={PeopleTagDetail} />
                                        <Route path="/people/blacklist" name="PeopleBlacklist" component={PeopleBlacklist} />

                                        <Route exact path="/otp/configuration" name="OtpConfiguration" component={OtpConfiguration} />
                                        <Route path="/otp/configuration/otpadd" name="OtpConfigurationAdd" component={OtpConfigurationAdd} />
                                        <Route exact path="/otp/log" name="OtpLog" component={OtpOtpLog} />
                                        <Route path="/otp/log/detail" name="OtpLog detial" component={OtpOtpLogDetial} />

                                        <Route path="/contactus" name="feedback" component={IssueReport} />

                                        <Route exact path="/report/" name="Report" component={Report} />
                                        <Route exact path="/report/createnew" name="Report Create" component={ReportCreate} />
                                        <Route path="/report/communication" name="ReportCommunication" component={ReportCommunication} />
                                        <Route path="/report/logs" name="Logs" component={Log} />
                                        <Route exact path="/report/Detailreportnew" name="Detailreportnew" component={ReportCreateDetailreportnew} />
                                        <Route exact path="/report/SummeryReportnew" name="SummeryReportnew" component={ReportCreateSummeryReportnew} />
                                        <Route exact path="/report/" name="Report" component={Report} />
                                        <Route exact path="/reportnew/" name="Report" component={ReportNew} />
                                        <Route exact path="/report/create" name="Report Create" component={ReportCreate} />
                                        <Route path="/report/communication" name="ReportCommunication" component={ReportCommunication} />
                                        <Route path="/report/logs" name="Logs" component={Log} />

                                        {
                                            admin || createuser || createaccount || userGenToken ?
                                                <Route exact path="/accountmanagement/" name="AccountList"
                                                    render={() => (
                                                        <AccountList onGetcreditbalance={this.getcredit} />
                                                    )} />
                                                :
                                                null
                                        }


                                        {/*createaccount ? <Route exact path="/accountmanagement/" name="UsertList" component={AccountList} /> : null*/}

                                        {createaccount ? <Route path="/accountmanagement/userDetail" name="UserDetail" component={AccountsDetail} /> : null}


                                        {admin || createuser || createaccount ? <Route path="/accountmanagement/userList" name="UserList" component={AccountList} /> : null}


                                        {admin || createuser || createaccount ? <Route path="/creditmovement" name="CreditMovement" component={CreditMovement} /> : null}


                                        <Route path="/home" name="home" component={Home} />
                                        <Route path="/Hello" name="hello" component={Hello}/>
                                        <Redirect from="/" to="/dashboard" />
                                    </Switch>
                                </Container>
                            </main>
                        </div>
                    </div>
                :
                    null 
                }
                <ModalServerError modalVisible={modalVisible} />
            </>
        );
    }
}

export default Full;
// 20210823