/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Tag, Icon, Button, Row, Col, Tooltip, Popover, Menu } from 'antd';

import { apiClient } from '../../../api';
import{root} from '../../../config';
import { token, deepEqual, CheckTokenExp } from '../../../config';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("Asia/Bangkok");

const dateFormat = 'DD/MM/YYYY';
const dateFormatDateTime = 'DD/MM/YYYY HH:mm';


export default class TableConfigurationOTP extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    title: 'OTC ID',
                    dataIndex: 'otcId',
                    key: 'otcId',
                    width: 240,
                },
                {
                    title: 'App OTP Name',
                    dataIndex: 'otcName',
                    key: 'otcName',
                    width: 200,
                    render: text => (<div className="table-title" title={text}>{text}</div>)
                },
                {
                    title: 'Pin Length',
                    dataIndex: 'pinLength',
                    key: 'pinLength',
                    width: 100,
                },
                {
                    title: 'Expire Time(Sec.)',
                    dataIndex: 'expireTime',
                    key: 'expireTime',
                    width: 100,
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    width: 150,
                    render: (status, text) => (
                        <Tag style={{ textAlign: 'center' }} className={status ? "tag-delivered" : "tag-expired"} >
                            {status ? "Active" : "Inactive"}
                        </Tag>
                    ),
                },
                {
                    title: 'Created Date',
                    dataIndex: 'CREATE_DATE',
                    key: 'CREATE_DATE',
                    width: 150
                },
                {
                    dataIndex: 'APP_ID',
                    key: 'APP_ID2',
                    width: 30,
                    align: 'right',
                    render: (key, item) => (
                        <div>
                            <Popover placement="bottomRight" content={
                                <Menu mode="vertical" >
                                    <Menu.Item key="0" style={{ borderBottom: '1px solid #7b7b7b' }}>
                                        <Link to={'/otp/configuration/otpadd?id=' + item.otcId} style={{ textDecoration: 'none' }} >
                                            <span className="fa fa-pencil-square-o ants-gray size14" ></span>&nbsp;&nbsp;  Edit
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="1" style={{ borderBottom: '1px solid #7b7b7b' }} onClick={() => {
                                            this.props.warningModal(true, "Do you want to delete this app OTP?", "Deleted app OTP successfully.");
                                            this.props.setStateByProps("action", "deletelist");
                                            this.props.setStateByProps("itemOtp", [{ otcId: item.otcId }]);
                                    }}>
                                        <span className="fa fa-trash-o ants-gray size14" ></span>&nbsp;&nbsp;  Delete
                                        </Menu.Item>
                                </Menu>
                            } trigger="click">
                                <span className="fa fa-ellipsis-v" style={{ cursor: 'pointer', width: 30, textAlign: 'center' }}></span>
                            </Popover>
                        </div>
                    ),
                },
            ],
            APPNAME: "",
            pagesize: 10,
            currentpage: 1,
            minpage: 1,
            enddata: 10,
            totalpage: 0,
            clickId: null,
        };

        this.getData = this.getData.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.setClickId = this.setClickId.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!deepEqual(this.props.search, prevProps.search)) {
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        if (!CheckTokenExp()) {
            this.setState({ loading: true });
            this.setState(state => {

                const { search } = this.props;

                let req = {
                    otcId: "",
                    otpName: search.AppName,
                    offset: state.minpage,
                    limit: state.pagesize,
                };
                apiClient.post('OTP/LIST', req
                , {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                    .then(data => {
                        var json = data.data.data.data;
                        this.setState(state => {
                            return {
                                data: json ? json : [],
                                totalpage: json.length > 0 ? data.data.data.total : 0,
                                currentpage: state.minpage == 1 ? 1 : state.currentpage,
                                minpage: 1
                            };
                        }, () => { this.props.updateWindowDimensions() });

                        this.setState({ loading: false });
                    })
                    .catch(error => {
                        this.setState({
                            data: [],
                            totalpage: 0,
                            currentpage: 1,
                            minpage: 1
                        });
                        this.setState({ loading: false });
                    });
            });
        } else {
            window.location.href = "logout";
        }
        
    }

    onChangePage(currentPage, pageSize) {
        this.setState({ loading: true });

        this.setState(state => {
            return {
                loading: true,
                currentpage: state.pagesize == pageSize ? currentPage : 1,
                pagesize: pageSize,
                minpage: currentPage,
            };
        });

        this.getData();
    }

    setClickId(item) {
        this.setState(state => {
            if (item) {
                console.table(item.otcId)
                window.location.href = 'otp/configuration/otpadd?id=' + item.otcId
            }
            return {
                clickId: item ? item.otcId : item,
            }
        })

    }

    render() {
        const { columns, loading, data, totalpage } = this.state;
        const { setStateByProps } = this.props;
        const rowSelection = {
            columnWidth: 30,
            onChange: (selectedRowKeys, selectedRows) => {
                setStateByProps("itemOtp", selectedRows);
            },
            getCheckboxProps: record => ({
                key: record.key,
            }),
        };
        return (
            <div>

                <Table
                    style={{ cursor: 'pointer' }}
                    loading={loading}
                    rowSelection={rowSelection}
                    rowKey={key => key.otcId}
                    pagination={{
                        defaultPageSize: 10,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: false,
                        total: totalpage,
                        showTotal: (total, range) => <div>&nbsp;&nbsp; {range[0]}-{range[1]} of {totalpage} items </div>,
                        onShowSizeChange: this.onChangePage,
                        onChange: this.onChangePage,
                        current: this.state.currentpage
                    }
                    }
                    onRow={(item, index) => ({
                        onDoubleClick: () => this.setClickId(item)
                    })}
                    columns={columns}
                    dataSource={data}
                    className="table-ants"
                    tableLayout="fixed"
                />
            </div>

        );
    }
}


