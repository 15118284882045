import CryptoJS from 'crypto-js';

export var token = document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
export var green = "#40d900";
export var red = "#ff2f2f";
export var colorGreen = "#00a651";
export var colorRed = "#c20303";
export var colorBlue = "#1a4378";

export var root ="";
// export var Title1 =  require('../src/img/Title1.png');  
export var Title =  require('../src/img/logoWebAllApp/Title.png');  
export var Logo_Web =  require('../src/img/logoWebAllApp/Logo_Web.png');  
export var Logo_Login = require('../src/img/logoWebAllApp/Logo_Login.png');  
export var title = require('../src/config.js'); 



export function set_cookie(value) {
    document.cookie = value;
    token = document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
};

export function capitalizeFLetter(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}


export function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;

}

export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function getFileExtension(filename) {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : ext[1].toLowerCase();
}

export function formatNumber(num) {
    return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
}

export function parseJwt(token) {
    if (token != "") {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } else {
        window.location.href = window.location.protocol + "//" + window.location.host;
    }
};

export function getAccId() {
    var token = document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    var payload = parseJwt(token);
    if (!payload.id) {
        window.location.href = "/logout"
    }
    return payload.id;
}

export function getId() {
    var token = document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    var payload = parseJwt(token);
    return payload.primarysid;

}

export function getUsername() {
    var token = document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    var payload = parseJwt(token);
    return payload.unique_name;

}

export function getPaytype() {
    var token = document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    var payload = parseJwt(token);
    return payload.paytype;

}

export function getLinkbacklist() {
    var token = document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    var payload = parseJwt(token);
    return payload.linkbacklist;

}

export function getDefaultPassword() {
    var token = document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    var payload = parseJwt(token);
    return payload.defaultPassword;

}

export function getTwoFactor() {
    var token = document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    var payload = parseJwt(token);
    return payload.twofactor;

}

export function getGenToken() {
    var token = document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    var payload = parseJwt(token);
    return payload.genToken;

}

export function getExpiredDate(token) {
    var payload = parseJwt(token);
    return new Date(payload.exp * 1000);

}

export function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

export function CheckTokenExp() {
    var tokenJwt = document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    if (tokenJwt == "") {
        return true;
    } else {
        return false;
    }
}

export function ReplaceUrlTaga(){
    // add new
    var element = document.getElementsByClassName('ant-breadcrumb-link');
    let start = 0;
    let end = element.length
    while (start < end) {
        if(element[start].getElementsByTagName('a')[0] != undefined){
            element[start].getElementsByTagName('a')[0].attributes[0].nodeValue = element[start].getElementsByTagName('a')[0].attributes[0].nodeValue.replace("#","") 
        }
        start++;
    }
}

export function UserInfo() {
    if (document.cookie.replace(/(?:(?:^|.*;\s*)__AntiXsrfUerkey\s*\=\s*([^;]*).*$)|^.*$/, "$1") != "") {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } else {
        window.location.href = window.location.protocol + "//" + window.location.host;
    }
};


export function hashPassword(stringToEncrypt) {
    try {
        // Create a key using the SEncryptionKey
        const key = CryptoJS.enc.Utf8.parse("M0m1jr0L");
        // Fixed IV as in the provided C# code
        const iv = CryptoJS.enc.Hex.parse('1234567890abcdef');
        // Encrypt the string
        const encrypted = CryptoJS.DES.encrypt(CryptoJS.enc.Utf8.parse(stringToEncrypt), key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
 
        // Return the encrypted string in Base64 format
        return encrypted.toString();
    } catch (error) {
        return error.message;
    }
  }